import {
  useNavigate,
  useParams,
  Outlet,
  useLoaderData,
} from "react-router-dom";
import { useEffect } from "react";

function RedirectToken({ children }) {
  const { sessionToken } = useParams();

  const sessionData = useLoaderData();

  const navigate = useNavigate();

  if (sessionData?.notSession) {
    window.location = `${process.env.REACT_APP_HOST_PLATFORM}`;
  }

  useEffect(() => {
    if (sessionToken) {
      if (
        sessionData?.redirectOrganizationPlan !== null &&
        sessionData?.user?.role === "organization"
      ) {
        navigate(
          `/organization/plan/${sessionData?.redirectOrganizationPlan}`,
          { replace: true },
        );
      } else {
        navigate("/", { replace: true });
      }
    }
  }, [sessionToken]);

  return null;
}

export default RedirectToken;
